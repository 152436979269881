/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import transactionInIcon from "../../assets/icons/transactionIn.svg";
import transactionOutIcon from "../../assets/icons/transactionOut.svg";
import walletIcon from "../../assets/icons/wallet.svg";
import addDataimg from "../../assets/img/add-data.svg";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import loadingSpinnerIcon from "../../assets/icons/spinner.svg";
import arrowIcon from "../../assets/icons/arrow.svg";
import {
  MdEdit,
  MdRemoveRedEye,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from "react-icons/md";
import smsIcon from "../../assets/icons/sms.svg";
import callIcon from "../../assets/icons/call.svg";
import { getFileFromKey } from "../../helpers/apis/user";

function Profile() {
  const auth = useSelector((state) => state.auth);
  const [transactions, setTransactions] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [watchlistLoading, setWatchlistLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [userStaffs, setUserStaffs] = useState([]);
  const [profileLoading, setProfileLoading] = useState(false);
  const axios = useAxiosPrivate();
  const fetchTransactions = () => {
    axios
      .get("/user/transactions?limit=5")
      .then((res) => {
        if (res?.data?.success) setTransactions(res.data.data);
      })
      .catch(() => {});
  };

  const fetchWatchlist = () => {
    axios
      .get("/user/watchlist?limit=5")
      .then((res) => {
        if (res?.data?.success) setWatchlist(res?.data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setWatchlistLoading(false);
      });
  };

  const fetchUserDetails = () => {
    axios
      .get("/user/profile")
      .then((res) => {
        if (res?.data?.success) setUserProfile(res?.data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setProfileLoading(false);
      });
  };

  const fetchUserStaffs = () => {
    axios
      .get("/user/staffs")
      .then((res) => {
        if (res?.data?.success) setUserStaffs(res?.data?.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    setProfileLoading(true);
    setWatchlistLoading(true);
    fetchUserDetails();
    fetchTransactions();
    fetchWatchlist();
    fetchUserDetails();
    fetchUserStaffs();
  }, []);

  const [coverImageUrl, setCoverImageUrl] = useState(
    userProfile.club?.profile || ""
  );
  useEffect(() => {
    const fetchImages = async () => {
      if (userProfile.club?.profile) {
        try {
          const result = await getFileFromKey(userProfile.club?.profile, auth);
          setCoverImageUrl(
            result.data.success ? result.data.url : userProfile.club?.profile
          );
        } catch (error) {
          console.error("Error fetching cover image:", error);
        }
      }
    };

    fetchImages();
  }, [userProfile.club, auth]);

  return (
    <div>
      <div className="max-w-[1400px] py-16 mx-auto px-5 sm:px-10 ">
        <div className="flex justify-between flex-col min-[1100px]:flex-row gap-10">
          <div className="min-[1100px]:w-50% flex-grow">
            <div className="rounded bg-white  border p-6 shadow-md">
              <div className="flex justify-between items-center">
                <div className="text-2xl font-semibold">{auth.name}</div>
                {/* <MdEdit /> */}
              </div>
              <div className="mt-4">
                <a
                  href={`mailto:${auth.email}`}
                  className="flex flex-nowrap gap-2 text-gray-600 items-center"
                >
                  <img src={smsIcon} alt="followers" className="w-5" />
                  <p className="text-ellipsis overflow-hidden">{auth.email}</p>
                </a>
                {auth.phone && (
                  <a
                    href={`tel:${auth.phone}`}
                    className="flex flex-nowrap gap-2 text-gray-600 items-center mt-4"
                  >
                    <img src={callIcon} alt="followers" className="w-5" />
                    <p>{auth.phone}</p>
                  </a>
                )}
              </div>
            </div>
            <div className="rounded bg-white border p-6 shadow-md mt-6">
              {profileLoading ? (
                <div className="w-full flex justify-center items-center h-[60vh]">
                  <img
                    src={loadingSpinnerIcon}
                    className="w-8 animate-spin"
                    alt="Loading..."
                  />
                </div>
              ) : !profileLoading && !userProfile?.name ? (
                <div className="w-full flex justify-center items-center h-[60vh]">
                  <p> Can&apos;t fetch data</p>
                </div>
              ) : (
                <div className="">
                  {userProfile?.club?.name ? (
                    <>
                      <h1 className="text-xl font-semibold mb-4">
                        Your Organization
                      </h1>
                      <div className="border rounded-md shadow-md p-4 flex justify-between gap-4 items-center w-full">
                        <div className="flex items-center justify-between gap-x-4 overflow-hidden">
                          <img
                            src={coverImageUrl}
                            className="rounded-lg h-20"
                            alt=""
                          />
                          <div className="overflow-hidden">
                            <div className="flex justify-between min-[1100px]:flex-row flex-col min-[1100px]:gap-6">
                              <div>
                                <h1 className="text-xl font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                                  {userProfile?.club?.name}
                                </h1>
                                <p className="text-slate-500">
                                  {userProfile.club.players} Players
                                </p>
                              </div>
                              {/* <div className="text-slate-500 flex gap-1 items-center">
                                <div
                                  className={`rounded-full w-2 h-2 mt-1 ${
                                    userProfile.club.status === "active"
                                      ? "bg-green-600"
                                      : userProfile.club.status === "awaiting"
                                      ? "bg-yellow-500"
                                      : userProfile.club.status === "rejected"
                                      ? "bg-black"
                                      : "bg-red-600"
                                  }`}
                                />
                                <p>{userProfile.club.status.charAt(0).toUpperCase() + userProfile.club.status.slice(1)}</p>
                              </div> */}
                            </div>

                            {/* <p className="text-slate-500">
                              {userProfile.club.followers} Followers
                            </p> */}
                          </div>
                        </div>
                        <Link
                          to="/user/club"
                          className="text-blue-800 font-medium"
                        >
                          <MdRemoveRedEye />
                        </Link>
                      </div>
                    </>
                  ) : (
                    <Link
                      to="/user/club"
                      className="border rounded-md py-2 px-3 flex justify-between gap-4 items-center w-full"
                    >
                      <h1>
                        You didn&apos;t created a organization, create now
                      </h1>
                      <div
                        to="/user/club"
                        className="text-blue-800 font-medium"
                      >
                        <img src={arrowIcon} className="w-7" alt="create new" />
                      </div>
                    </Link>
                  )}
                </div>
              )}
            </div>
            {auth.role === "user" ||
            auth?.access?.some(
              (item) =>
                item.title === "staffManagement" &&
                item.enabled &&
                (item.type === "Read Only" || item.type === "Read & Write")
            ) ? (
              <>
                {userProfile?.club?.name && (
                  <div className="rounded bg-white border p-6 shadow-md mt-6">
                    {userStaffs.length > 0 ? (
                      <div>
                        <h1 className="text-xl font-semibold mb-4">Staff's</h1>
                        {userStaffs.map((staff, index) => (
                          <div
                            key={index}
                            className="border rounded-md shadow-md p-4 flex justify-between items-center gap-4 mb-4"
                          >
                            {/* Staff Details */}
                            <div className="flex items-center gap-x-4 overflow-hidden flex-grow">
                              <div className="overflow-hidden">
                                <h1 className="text-xl font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                                  {staff.name}
                                </h1>
                                <div className="flex items-center gap-6 capitalize">
                                  <p className="text-slate-500">{staff.role}</p>
                                  {/* Status Indicator */}
                                  <div className="flex items-center gap-1 text-slate-500">
                                    <div
                                      className={`rounded-full w-2 h-2 mt-1 ${
                                        staff.status === "active"
                                          ? "bg-green-600"
                                          : staff.status === "awaiting"
                                          ? "bg-yellow-500"
                                          : staff.status === "rejected"
                                          ? "bg-black"
                                          : "bg-red-600"
                                      }`}
                                    />
                                    <p>{staff.status}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Edit Link */}
                            {(auth.role === "user" ||
                              auth?.access?.some(
                                (item) =>
                                  item.title === "staffManagement" &&
                                  item.enabled &&
                                  item.type === "Write Only"
                              )) && (
                              <Link
                                to={`/user/staff/${staff.staffId}`}
                                className="text-blue-800 font-medium flex items-center gap-1"
                              >
                                <MdEdit className="w-5 h-5" /> {/* Edit Icon */}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-500 text-center">
                        No Staffs Found
                      </p>
                    )}
                  </div>
                )}
              </>
            ) : null}
          </div>
          <div className="w-full min-[1100px]:w-[40%]">
            <div className="rounded bg-white border p-6 shadow-md ">
              {transactions.length ? (
                <div className="">
                  <h1 className="text-xl font-semibold mb-4">Transactions</h1>
                  {transactions.map((ele) => (
                    <div key={ele._id}>
                      {ele.from === userProfile?.club?.name ? (
                        <div className="rounded bg-gradient-to-r from-[#FFFEFE] to-[#FFE5E5] border border-red-600 p-4 shadow-md flex gap-x-2 my-4 justify-start items-center">
                          <MdKeyboardArrowDown
                            size={24}
                            className="text-red-600"
                          />
                          <div className="flex w-full justify-between items-center">
                            <div>
                              <h1 className="text-black my-0">
                                <span className="text-slate-500 text-sm">
                                  To :{" "}
                                </span>
                                {ele.to}
                              </h1>
                              <p className="-mt-1 text-slate-400 text-sm">
                                {ele.transaction_date}
                              </p>
                            </div>
                            <p className="text-lg text-red-600">
                              -{ele.amount}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="rounded bg-gradient-to-r from-[#FBFFF6] to-[#F2FFE5] border border-green-600 p-4 shadow-md flex gap-x-2 box-border my-4 justify-start items-center">
                          <MdKeyboardArrowUp
                            size={24}
                            className="text-green-600"
                          />
                          <div className="flex w-[90%] max-w-full justify-between gap-x-2 items-center">
                            <div className="overflow-hidden">
                              <p className="text-black my-0 text-ellipsis whitespace-nowrap overflow-hidden">
                                <span className="text-slate-500 text-sm">
                                  From :{" "}
                                </span>
                                {ele.from}
                              </p>
                              <p className="-mt-1 text-slate-400 text-sm">
                                {ele.transaction_date}
                              </p>
                            </div>
                            <p className="text-lg w-max text-green-600">
                              +{ele.amount}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                "No Transactions"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
