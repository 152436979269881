/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";

import RightDrawer from "../../Drawer/RightDrawer";
import { CssTextField, SelectField } from "../../InputFields/InputFields";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useErrorToast } from "../../../hooks/useToast";
import CenterPopup from "../../Popup/CenterPopUp";
import { MenuItem, Select, Switch } from "@mui/material";
import { useSelector } from "react-redux";
import { getFileFromKey } from "../../../helpers/apis/user";

function MatchData({ close, data, openState, isHost, tournamentId, fetchTournament }) {
  const [updatingResult, setUpdatingResult] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [isDraw, setIsDraw] = useState(false);
  const [tieBreaker, setTieBreaker] = useState(false);
  const [tieBreakerType, setTieBreakerType] = useState("");
  const [teamAImage, setTeamAImage] = useState();
  const [teamBImage, setTeamBImage] = useState();
  const [isClosing, setIsClosing] = useState(false);
  const axios = useAxiosPrivate();
  const auth = useSelector((state) => state.auth);


  const fetchImage = async (key) => {
    try {
      const result = await getFileFromKey(key, auth);
      return result.data.success ? result.data.url : key;
    } catch (error) {
      console.error("Error fetching image:", error);
      return key;
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      if (data?.match?.teamA?.profile) {
        const teamAUrl = await fetchImage(data.match.teamA.profile);
        setTeamAImage(teamAUrl);
      }
      if (data?.match?.teamB?.profile) {
        const teamBUrl = await fetchImage(data.match.teamB.profile);
        setTeamBImage(teamBUrl);
      }
    };
    fetchImages();
  }, [data, auth]);

  const shortName = (name) => {
    return name.split(" ").map((word) => word[0]).join("");
  };

  const formik = useFormik({
    initialValues: {
      teamA: data?.match?.teamA?.goals >= 0 ? data?.match?.teamA?.goals : 0,
      teamB: data?.match?.teamB?.goals >= 0 ? data?.match?.teamB?.goals : 0,
      tieBreakerTeamA: 0,
      tieBreakerTeamB: 0,
    },

    validationSchema: yup.object().shape({
      teamA: yup
        .number()
        .typeError(" ")
        .required(" ")
        .min(0, " "),
      teamB: yup
        .number()
        .typeError(" ")
        .required(" ")
        .min(0, " "),
      tieBreaker: yup.boolean(),
      tieBreakerTeamA: yup
        .number()
        .typeError(" ")
        .min(0, " ")
        .when(['tieBreaker', 'teamA', 'teamB'], {
          is: (tieBreaker, teamA, teamB) => tieBreaker && teamA === teamB,
          then: (schema) => schema
            .required('Tie-breaker score is required when match is tied')
            .test('different-scores', 'Tie-breaker scores must be different', 
              function(value) {
                const { tieBreakerTeamB } = this.parent;
                return value !== tieBreakerTeamB;
              }
            )
        }),
      tieBreakerTeamB: yup
        .number()
        .typeError(" ")
        .min(0, " ")
        .when(['tieBreaker', 'teamA', 'teamB'], {
          is: (tieBreaker, teamA, teamB) => tieBreaker && teamA === teamB,
          then: (schema) => schema
            .required('Tie-breaker score is required when match is tied')
            .test('different-scores', 'Tie-breaker scores must be different', 
              function(value) {
                const { tieBreakerTeamA } = this.parent;
                return value !== tieBreakerTeamA;
              }
            )
        }),
    }),

    onSubmit: async (values, { resetForm }) => {

      if (tieBreaker && values.tieBreakerTeamA === values.tieBreakerTeamB) {
        useErrorToast({
          message: "Tie-breaker scores must be different",
        });
        return;
      }
      const matchResult = {
        ...values,
        match_no: data?.match?.match_no,
        round_no: data?.round?.round_no,
        draw: values.teamA === values.teamB ? true : false,
        tieBreakerType: tieBreakerType || null,
        tieBreakerTeamA: values.tieBreakerTeamA,
        tieBreakerTeamB: values.tieBreakerTeamB,
      };

      const endpoint = isFinalizing
        ? `/user/tournament/${tournamentId}/result`
        : `/user/tournament/${tournamentId}/score`;

      try {
        const res = await axios.post(
          endpoint,
          isFinalizing ? { ...matchResult, complete: true } : matchResult
        );

        if (res.data.success) {
          await fetchTournament();
          handleClose();
        } else {
          useErrorToast({
            message: res.data.message || "Something went wrong",
          });
        }
      } catch (err) {
        useErrorToast({
          message: err.response.data.message || "Something went wrong",
        });
      } finally {
        setUpdatingResult(false);
        setIsFinalizing(false);
      }
    },
  });
  const handleUpdate = async () => {
    if (!formik.isValid) {
      return;
    }
    await formik.submitForm();
  };

  const handleFinalize = async () => {
    if (!formik.isValid) {
      return;
    }
    setIsFinalizing(true);
    await formik.submitForm();
  };

  useEffect(() => {
    formik.setValues({
      teamA: data?.match?.teamA?.goals >= 0 ? data?.match?.teamA?.goals : 0,
      teamB: data?.match?.teamB?.goals >= 0 ? data?.match?.teamB?.goals : 0,
    });
  }, [data]);

  useEffect(() => {
    setIsDraw(formik.values.teamA === formik.values.teamB);
    if (formik.values.teamA !== formik.values.teamB) {
      setTieBreaker(false);
      setTieBreakerType("");
    }
  }, [formik.values.teamA, formik.values.teamB]);

  const isComplete = data?.match?.complete;

  const handleClose = () => {
    setIsClosing(true);
    close();
  };

  const handleExited = () => {
    if (isClosing) {
      setTeamAImage(null);
      setTeamBImage(null);
      formik.resetForm();
      setTieBreaker(false);
      setTieBreakerType("");
      setIsClosing(false);
    }
  };

  return (
    <CenterPopup
      title={`Match ${parseInt(data?.match?.match_no, 10) + 1} - ${data?.round?.round_name}`}
      openState={openState}
      close={handleClose}
      onExited={handleExited}
    >
      <div>
        <div className="px-3 py-2 my-1">
          <div className="flex justify-between mb-2 items-center">
            <div className="flex flex-col gap-y-3 min-[400px]:w-[150px] items-start">
              <div className="flex gap-x-2 items-center">
                <img
                  src={teamAImage || "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"}
                  className="w-8 h-8 rounded-full"
                  alt="Team A"
                />
                <h1 className="text-left">
                  {data?.match?.teamA?.name ? (
                    shortName(data?.match?.teamA.name)
                  ) : (
                    <span className="text-slate-500">Team A</span>
                  )}
                </h1>
              </div>
              {isHost && !isComplete && (
                <CssTextField
                  sx={{ width: "100px" }}
                  type="number"
                  name="teamA"
                  hiddenLabel
                  error={formik.touched.teamA && Boolean(formik.errors.teamA)}
                  value={formik.values.teamA}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  size="small"
                />
              )}
            </div>
            <div className="flex gap-x-2">
              <h1 className="text-lg font-medium">{formik.values.teamA >= 0 ? formik.values.teamA : ""}</h1>
              <h1 className="text-slate-400 font-medium">-</h1>
              <h1 className="text-lg font-medium">{formik.values.teamB >= 0 ? formik.values.teamB : ""}</h1>
            </div>
            <div className="flex flex-col gap-y-3 min-[400px]:w-[150px] items-end">
              <div className="flex gap-x-2 items-center">
                <h1 className="text-right">
                  {data?.match?.teamB?.name ? (
                    shortName(data?.match?.teamB.name)
                  ) : (
                    <span className="text-slate-500">Team B</span>
                  )}
                </h1>
                <img
                  src={teamBImage || "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"}
                  className="w-7 h-7 rounded-full"
                  alt="Team B"
                />
              </div>
              {isHost && !isComplete && (
                <CssTextField
                  sx={{ width: "100px" }}
                  type="number"
                  name="teamB"
                  error={formik.touched.teamB && Boolean(formik.errors.teamB)}
                  value={formik.values.teamB}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hiddenLabel
                  size="small"
                />
              )}
            </div>
          </div>

          {isDraw && isHost && !isComplete && (
            <div className="mt-3">
              <div className="flex justify-center items-center mb-2">
                <span>Tie Breaker</span>
                <Switch
                  type="checkbox"
                  checked={tieBreaker}
                  onChange={() => setTieBreaker(!tieBreaker)}
                />
              </div>
              {tieBreaker && (
                <div>
                  <div className="flex flex-col gap-y-3 my-5 items-center mx-auto w-8/12">
                    <SelectField
                      value={tieBreakerType}
                      onChange={(e) => setTieBreakerType(e.target.value)}
                      variant="outlined"
                      options={[{ value: "penalty shootout", label: "Penalty Shootout" }]}
                      label="Select Tie Breaker Type"
                      size="small"
                    />
                  </div>

                  <div className="flex flex-col gap-y-3 mb-2 items-center">
                    {tieBreakerType && (
                      <div className="flex flex-col items-center mb-2">
                        <div className="flex gap-x-2 items-center">
                          <img
                            src={
                              teamAImage ||
                              "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"
                            }
                            className="w-7 h-7 rounded-full"
                            alt="Team A"
                          />
                          <CssTextField
                            sx={{ width: "100px" }}
                            type="number"
                            name="tieBreakerTeamA"
                            hiddenLabel
                            error={formik.touched.tieBreakerTeamA && Boolean(formik.errors.tieBreakerTeamA)}
                            value={formik.values.tieBreakerTeamA}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            size="small"
                          />
                          <span className="text-slate-400 font-medium mx-2">-</span>
                          <CssTextField
                            sx={{ width: "100px" }}
                            type="number"
                            name="tieBreakerTeamB"
                            hiddenLabel
                            error={formik.touched.tieBreakerTeamB && Boolean(formik.errors.tieBreakerTeamB)}
                            value={formik.values.tieBreakerTeamB}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            size="small"
                          />
                          <img
                            src={
                              teamBImage ||
                              "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"
                            }
                            className="w-7 h-7 rounded-full"
                            alt="Team B"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}





          <div className="mt-3 flex justify-center">
            {isHost && !isComplete && (
              <div className="flex space-x-3">
                <button
                  type="button"
                  className="border-2 border-green-800 px-3 py-1 text-sm text-green-800 rounded"
                  onClick={handleUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="border-2 border-green-800 px-3 py-1 text-sm text-green-800 rounded"
                  onClick={handleFinalize}
                >
                  Finalize
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </CenterPopup>
  );
}

MatchData.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  openState: PropTypes.bool.isRequired,
  isHost: PropTypes.bool,
  tournamentId: PropTypes.string.isRequired,
};

export default MatchData;
