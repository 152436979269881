/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Notfound from "../../pages/Notfound";
import spinnerIcon from "../../assets/icons/spinner.svg";
import { MdOutlineLocationOn, MdCalendarMonth } from "react-icons/md";
import { InputSubmit } from "../InputFields/InputFields";
import { useSelector } from "react-redux";
import { getFileFromKey } from "../../../src/helpers/apis/user";
import { getTournament } from "../../helpers/apis/guest";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";
import { MdArrowBack } from "react-icons/md";
import { IoIosFootball } from "react-icons/io";
import "./Tournament.scss";
import RegisterForm from "./RegisterForm";
import About from "./About";
import MatchTab from "./MatchTab";
import TableTab from "./TableTab";

function TournamentMain() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [dataFound, setDataFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [registerModal, setRegisterModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState();
  const auth = useSelector((state) => state.auth);
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [teamImages, setTeamImages] = useState([]);
  const [activeTab, setActiveTab] = useState("about");

  const fetchTournament = async () => {
    try {
      const res = await getTournament(id, auth);
      if (res.data.success) {
        setData(res.data.data);
        setIsRegistered(res.data.data?.isRegistered);
        setDataFound(true);
      }
    } catch {
      setDataFound(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournament();
  }, [id, auth, isRegistered]);

  const fetchImages = async () => {
    try {
      const coverResult = data?.cover
        ? await getFileFromKey(data.cover, auth)
        : null;
      setCoverImageUrl(
        coverResult?.data.success ? coverResult.data.url : data.cover
      );

      const fetchedTeamImages = await Promise.all(
        data?.teams.map(async (team) => {
          try {
            const result = await getFileFromKey(team.profile, auth);
            return result.data.success ? result.data.url : team.profile;
          } catch {
            return team.profile;
          }
        })
      );

      setTeamImages(fetchedTeamImages);
    } catch (error) {
      console.error("Error fetching images:", error);
      useErrorToast({ message: "Error fetching images" });
    } finally {
    }
  };

  useEffect(() => {
    if (data?._id) {
      fetchImages();
    }
  }, [data, auth]);

  const renderContent = () => {
    switch (activeTab) {
      case "about":
        return <About data={data} isRegistered={isRegistered} />;
      case "matches":
        return <MatchTab data={data} fetchTournament={fetchTournament} />;
      case "scoreTable":
        return <TableTab data={data} fetchTournament={fetchTournament} />;
      default:
        return <About data={data} isRegistered={isRegistered} />;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <img src={spinnerIcon} className="w-9 animate-spin" alt="Loading..." />
      </div>
    );
  }

  if (!dataFound) return <Notfound />;
  return (
    <>
      <div>
        <button
          className="flex items-center gap-2 mb-6 mx-4 md:mx-8 lg:mx-12"
          onClick={() => navigate("/explore")}
        >
          <MdArrowBack />
          Back
        </button>
      </div>
      <div className="bg-white border shadow-md rounded-lg p-6 mx-4 md:mx-6 min-[700px]:mx-12">
        <div className="flex flex-col sm:flex-row sm:justify-between items-center md:gap-4">
          <div className="rounded-full w-fit bg-white border-gray-400 border p-2 mb-4 sm:mb-0">
            <img
              src={coverImageUrl}
              alt="profile"
              className="w-24 h-24 sm:w-28 sm:h-28 rounded-full"
            />
          </div>
          <div className="flex flex-col gap-2 w-full sm:w-[85%]">
            <div className="flex justify-between">
              <p className="text-xl sm:text-2xl font-bold">{data.title}</p>
            </div>
            <div className="flex items-center gap-1 truncate">
              <div>
                <MdOutlineLocationOn />
              </div>
              <div className="whitespace-pre-line overflow-ellipsis overflow-hidden">
                {data?.location}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-2 justify-between sm:items-end ">
              <div className="">
                <div className="flex items-center gap-1">
                  <div>
                    <MdCalendarMonth />
                  </div>
                  <div className="whitespace-nowrap">{data?.start_date}</div>
                </div>
                <div className="flex items-center gap-1">
                  <div><IoIosFootball/></div>
                  <div className="whitespace-nowrap">
                    {(() => {
                      switch (data?.tournament_type) {
                        case "t1":
                          return "League";
                        case "t2":
                          return "Knockout";
                        case "t3":
                          return "Group Stage + Knockout";
                        default:
                          return "Unknown Type";
                      }
                    })()}
                  </div>
                </div>
              </div>
              <div className="mt-2 sm:mt-0 flex justify-end">
                {isRegistered ? (
                  <button
                    className="border border-secondary rounded text-secondary w-full bg-secondary text-white px-3 py-1 cursor-default"
                    type="button"
                    disabled
                  >
                    Registered
                  </button>
                ) : data?.teams?.length === data.no_teams ? (
                  <div className="w-full flex items-center">
                    <p className="text-center text-red-600">
                      Registration limit exceeded
                    </p>
                  </div>
                ) : data?.registration?.status === "open" && !data?.isHost ? (
                  <InputSubmit
                    type="button"
                    value="Register"
                    className="w-1/2 sm:w-1/3 min-w-[130px] h-9"
                    onClick={() => {
                      if (!auth.name)
                        navigate("/login", {
                          state: { from: location.pathname },
                        });
                      else setRegisterModal(true);
                    }}
                  />
                ) : data?.registration?.status === "open" ? (
                  <button
                    className="hover:shadow border border-secondary rounded text-secondary w-full hover:bg-gradient-to-r from-secondary-start to-secondary-end hover:text-white px-3 py-1"
                    type="button"
                    onClick={() => setRegisterModal(true)}
                  >
                    Register
                  </button>
                ) : (
                  <div className="w-full flex items-center">
                    <p className="text-center text-red-600">
                      Registration closed
                    </p>
                  </div>
                )}

                {registerModal === true && (
                  <RegisterForm
                    data={data}
                    setIsRegistered={setIsRegistered}
                    showMessage={(arg) => {
                      if (arg.type === "success")
                        useSuccessToast({ message: arg.message });
                      else useErrorToast({ message: arg.message });
                    }}
                    openState={registerModal}
                    close={() => setRegisterModal(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1400px] mx-auto px-4 md:px-6 lg:px-10">
        <div className="flex flex-col mt-8">
          <div className="flex gap-1 overflow-auto ml-auto mr-0">
            <button
              onClick={() => setActiveTab("about")}
              className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${
                activeTab === "about" ? "text-primary" : "text-gray-500"
              }`}
            >
              About
            </button>
            {dataFound &&
              ["scheduled"].includes(data?.registration?.status) && (
                <>
                  <button
                    onClick={() => setActiveTab("matches")}
                    className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${
                      activeTab === "matches" ? "text-primary" : "text-gray-500"
                    }`}
                  >
                    Matches
                  </button>
                  {data.tournament_type !== "t2" && (
                    <button
                      onClick={() => setActiveTab("scoreTable")}
                      className={`px-2 py-1 text-xs sm:text-base sm:px-3 sm:py-1.5 ${
                        activeTab === "scoreTable"
                          ? "text-primary"
                          : "text-gray-500"
                      }`}
                    >
                      Score Table
                    </button>
                  )}
                </>
              )}
          </div>
          <div className="mt-4">{renderContent()}</div>
        </div>
      </div>
    </>
  );
}

TournamentMain.propTypes = {
  data: PropTypes.object,
  setIsRegistered: PropTypes.func,
};

export default TournamentMain;
