/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import smsIcon from "../../assets/icons/sms.svg";
import callIcon from "../../assets/icons/call.svg";
import calendarIcon from "../../assets/icons/calendar-tick-dark.svg";
import locationIcon from "../../assets/icons/location-dark.svg";
import ticketIcon from "../../assets/icons/ticket.svg";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import spinnerIcon from "../../assets/icons/spinner.svg";
import { InputSubmit } from "../InputFields/InputFields";
import { getFileFromKey } from "../../helpers/apis/user";

function CountdownTimer({ date }) {
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const targetDate = new Date(date);
      targetDate.setDate(targetDate.getDate() + 1);
      const currentDate = new Date();
      const timeDiff = targetDate.getTime() - currentDate.getTime();
      setCountdown({
        days: Math.floor(timeDiff / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0"),
        hours: Math.floor((timeDiff / (1000 * 60 * 60)) % 24)
          .toString()
          .padStart(2, "0"),
        minutes: Math.floor((timeDiff / (1000 * 60)) % 60)
          .toString()
          .padStart(2, "0"),
        seconds: Math.floor((timeDiff / 1000) % 60)
          .toString()
          .padStart(2, "0"),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return (
    <div className="border rounded-md px-3 py-4">
      <h1 className="my-2 text-base sm:text-lg mb-3 text-slate-500 text-center">
        Registration closes in
      </h1>
      <div className="flex justify-center items-center">
        <div className="mx-auto">
          <p className="text-lg sm:text-2xl text-center font-medium">
            {countdown.days}
          </p>
          <p className="text-xs sm:text-sm text-center text-slate-600">Days</p>
        </div>
        <div className="mx-auto">
          <p className="text-lg sm:text-2xl text-center font-medium">
            {countdown.hours}
          </p>
          <p className="text-xs sm:text-sm text-center text-slate-600">Hours</p>
        </div>
        <div className="mx-auto">
          <p className="text-lg sm:text-2xl text-center font-medium">
            {countdown.minutes}
          </p>
          <p className="text-xs sm:text-sm text-center text-slate-600">
            Minutes
          </p>
        </div>
        <div className="mx-auto">
          <p className="text-lg sm:text-2xl text-center font-medium">
            {countdown.seconds}
          </p>
          <p className="text-xs sm:text-sm text-center text-slate-600">
            Seconds
          </p>
        </div>
      </div>
    </div>
  );
}

function About({ data }) {
  const [isRegistered, setIsRegistered] = useState(data?.isRegistered);
  const [tournamentScheduler, setTournamentScheduler] = useState(false);
  const [isTournamentScheduled, setIsTournamentScheduled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const axios = useAxiosPrivate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchImages = async () => {
      if (!data?.host?.profile) return; // Ensure there's a profile to fetch

      try {
        const result = await getFileFromKey(data.host.profile, auth);
        if (result.data.success) {
          setCoverAboutImageUrl(result.data.url); // Set the fetched URL
        } else {
          setCoverAboutImageUrl(data.host.profile); // Fallback to original profile URL
        }
      } catch (error) {
        console.error("Error fetching cover image:", error);
        setCoverAboutImageUrl(data.host.profile); // Fallback to original profile URL
      }
    };

    fetchImages();
  }, [data?.host?.profile, auth]); // Dependency array ensures it runs on host profile changes



  // adding razorpay script to body
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleBuyTickets = async () => {
    try {
      // generate invoice
      const res = await axios.post(`/user/payment/initiate-order`, {
        amount: ticketQuantity * data.ticket.amount,
      });
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: res?.data?.data?.order.amount,
        currency: "INR",
        name: "Impetus",
        description: "Ticket Fee Payment",
        image: "/logo-sm.svg",
        order_id: res?.data?.data?.order.id,
        handler(response) {
          // on successfull saving data
          const buyticket = axios
            .post(`/user/tournament/${data._id}/buy-ticket`, {
              ...response,
              quantity: ticketQuantity,
              amount: res?.data?.data?.order.amount,
            })
            .then((response) => {
              // showMessage({
              //   type: "success",
              //   message: "Registered successfully",
              // });
              // close();
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => { });
        },
        theme: {
          color: "#F2811D",
        },
      };
      // eslint-disable-next-line no-undef
      const rzp = new Razorpay(options);
      rzp.open();

      // close();

      // }
    } catch (err) {
      useErrorToast({
        message:
          err?.response?.data?.message || "Something went wrong, try again",
      });
    }
  };

  const scheduleTournament = () => {
    setTournamentScheduler(true);
    axios
      .get(`/user/tournament/${data._id}/schedule`)
      .then((res) => {
        if (res?.data?.success) {
          useSuccessToast({
            message: res?.data?.message || "Tournament scheduled",
          });
          setIsTournamentScheduled(true);
          window.location.reload();
        } else {
          useErrorToast({
            message: res?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((err) => {
        useErrorToast({
          message: err?.response?.data?.message || "Something went wrong",
        });
      })
      .finally(() => {
        setTournamentScheduler(false);
      });
  };

  // const buyTickets = (quantity) => {
  //   setTournamentScheduler(true);
  //   axios
  //     .post(`/user/tournament/${data._id}/buy-ticket`, { quantity })
  //     .then((res) => {
  //       if (res?.data?.success) {
  //         useSuccessToast({
  //           message: res?.data?.message || "Tickets purchased successfully",
  //         });
  //         setIsModalOpen(false);
  //       } else {
  //         useErrorToast({
  //           message: res?.data?.message || "Failed to purchase tickets",
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       useErrorToast({
  //         message: err?.response?.data?.message || "Something went wrong",
  //       });
  //     })
  //     .finally(() => {
  //       setTournamentScheduler(false);
  //     });
  // };

  // const handleBuyTickets = () => {
  //   buyTickets(ticketQuantity);
  // };

  const TicketModal = () => (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${isModalOpen ? "block" : "hidden"
        }`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Buy Tickets
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Select the number of tickets you want to purchase.
                  </p>
                  <div className="mt-4">
                    <label
                      htmlFor="quantity"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantity
                    </label>
                    <input
                      type="number"
                      name="quantity"
                      id="quantity"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      value={ticketQuantity}
                      onChange={(e) =>
                        setTicketQuantity(Math.max(1, parseInt(e.target.value)))
                      }
                      min="1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleBuyTickets}
            >
              Confirm Purchase
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );


const [teamUrls, setTeamUrls] = useState({});

useEffect(() => {
  // Fetch team URLs
  const fetchTeamsUrls = async () => {
    try {
      // Fetch URLs for first 5 teams only .slice(0, 5)
      const urlsPromises = data.teams.map(async (team) => {
        try {
          const result = await getFileFromKey(team.profile, auth);
          return { id: team._id, url: result.data.success ? result.data.url : team.profile };
        } catch (error) {
          console.error(`Error fetching file for team ID ${team._id}:`, error);
          return { id: team._id, url: team.profile }; // Fallback to original profile path
        }
      });

      // Wait for all promises to resolve
      const urls = await Promise.all(urlsPromises);

      // Convert array to object for efficient access
      const urlsObject = urls.reduce((acc, { id, url }) => {
        acc[id] = url;
        return acc;
      }, {});

      setTeamUrls(urlsObject);
    } catch (error) {
      console.error("Error fetching team URLs:", error);
    }
  };

  // Call function if teams exist
  if (data.teams?.length > 0) {
    fetchTeamsUrls();
  }
}, [data.teams, auth]);


  const [coverAboutImageUrl, setCoverAboutImageUrl] = useState(
    data?.host.profile || ""
  );

  useEffect(() => {
    const fetchImages = async () => {
      if (!data?.host?.profile) return;

      try {
        const result = await getFileFromKey(data.host.profile, auth);
        if (result.data.success) {
          setCoverAboutImageUrl(result.data.url);
        } else {
          setCoverAboutImageUrl(data.host.profile);
        }
      } catch (error) {
        console.error("Error fetching cover image:", error);
      }
    };

    fetchImages();
  }, [data?.host?.profile, auth]);


  return (
    <div className="mb-10 mt-2">
      <p className="text-base sm:text-lg font-semibold mb-2">
        About Tournament
      </p>
      <div className="text-sm sm:text-base text-gray-600 break-words">
        {data?.description}
      </div>

      <div className="flex flex-col sm:flex-row my-6 justify-between items-center bg-white p-6 gap-6">
        <div className="flex flex-col justify-between w-full sm:w-1/2">
          <div className="my-4">
            <p className="text-lg sm:text-xl font-semibold mb-3">
              Registered teams
            </p>
            {data?.teams?.length ? (
              <div className="flex flex-row gap-1">
                {/* if only 5 teams then use .slice(0, 5) */}
                {data.teams.map((ele) => (
                  <span
                    key={ele._id}
                    title={ele.name}
                    className="tournament-card_avatar relative border border-white/80 rounded-full overflow-hidden w-[30px]"
                  >
                    <img
                      src={teamUrls[ele._id]}
                      className="h-full"
                      alt="profiles"
                    />
                  </span>
                ))}
                {/* {data.teams.length > 5 && (
                  <span className="tournament-card_avatar relative border flex justify-center text-xs items-center bg-white border-white/80 rounded-full overflow-hidden w-[30px]">
                    {data.teams.length - 5}+
                  </span>
                )} */}
              </div>
            ) : (
              <div className="w-full flex items-center h-20">
                <p className="my-auto align-middle mx-auto text-slate-500">
                  Zero Registration ...
                </p>
              </div>
            )}
          </div>
          <div className="">
            {!data?.isRegistered && data?.registration?.status === "open" && data?.teams?.length !== data.no_teams ? (
              <div className="my-4">
                <CountdownTimer date={data?.registration?.last_date} />
              </div>
            ) : (
              data?.isHost &&
              data?.registration?.status === "closed" &&
              !isTournamentScheduled && (
                <div className="my-4 rounded-md px-3 py-4 flex flex-col gap-y-2 justify-between items-center">
                  <p className="text-base sm:text-lg font-medium">
                    Schedule tournament
                  </p>
                  <button
                    className="hover:shadow border justify-center flex gap-x-2 w-full disabled:bg-slate-300 disabled:border-slate-300 disabled:text-gray-600 border-secondary rounded text-secondary hover:bg-gradient-to-r from-secondary-start to-secondary-end hover:text-white px-3 py-1"
                    type="button"
                    onClick={() => {
                      scheduleTournament();
                    }}
                    disabled={tournamentScheduler}
                  >
                    {tournamentScheduler && (
                      <img
                        src={spinnerIcon}
                        className="animate-spin w-5"
                        alt="..."
                      />
                    )}
                    Schedule
                  </button>
                </div>
              )
            )}
          </div>
        </div>
        {/* {data?.ticket?.is && data.start_date && (
          <div className="my-6 w-full sm:w-1/2">
            <p className="text-lg sm:text-xl font-semibold mb-3">Buy tickets</p>
            <div className="border rounded-md py-2 px-3">
              <p className="text-sm sm:text-base text-gray-600">{data?.host?.name}</p>
              <p className="text-base sm:text-lg -mt-1 mb-4 font-medium">
                {data?.title}
              </p>
              <div className="flex flex-wrap gap-x-8 gap-y-4">
                <div className="flex flex-col gap-y-1">
                  <div className="flex items-center gap-1">
                    <img src={calendarIcon} alt="calendar" />
                    <p className="text-xs sm:text-sm">{data?.start_date}</p>
                  </div>
                  <div className="flex items-center gap-1">
                    <img src={locationIcon} alt="calendar" />
                    <p className="text-xs sm:text-sm">
                      {data?.location}
                      {data?.state}, {data?.country}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={ticketIcon} alt="calendar" className="w-4" />
                    <p className="text-xs sm:text-sm">Tickets are available</p>
                  </div>
                  {data?.ticket?.total <= 5 ? (
                    <div className="flex items-center gap-1">
                      <p className="text-xs sm:text-sm text-red-600 mt-1">
                        Only {data?.ticket?.total} is available
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="border border-dashed my-4 w-full" />
              <div className="flex justify-between">
                <div>
                  <p className="">
                    ₹
                    <span className="text-2xl sm:text-3xl font-semibold">
                      {data?.ticket?.amount}
                    </span>
                  </p>
                </div>
                <InputSubmit
                  type="button"
                  disabled={false}
                  value="Buy now"
                  className="w-1/3 min-w-[100px] h-9"
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            </div>
          </div>
        )} */}
      </div>

      <div className="my-8 bg-white p-4 sm:bg-inherit sm:p-0">
        <div className="font-semibold text-base sm:text-lg mb-4">
          About Organizer
        </div>
        <div className="flex flex-col sm:flex-row flex-wrap items-center gap-y-6 gap-x-8">
          <div className="flex items-center">
            <Link to={`/club/${data?.host?._id}`}>
              <img
                className="h-12 w-12 sm:h-16 sm:w-16 rounded-full mr-2"
                src={coverAboutImageUrl}
                alt={data?.host.name}
              />
            </Link>
            <Link
              to={`/club/${data?.host?._id}`}
              className="text-lg sm:text-xl font-semibold"
            >
              {data?.host.name}
            </Link>
          </div>
          <div className="border-l-2 h-8 sm:h-10 sm:block hidden" />
          <div className="flex sm:flex-row flex-col sm:gap-6 gap-2 justify-end items-center">
            <a
              href={`mailto:${data?.host.email}`}
              className="flex flex-nowrap gap-2 text-gray-600 items-center"
            >
              <img src={smsIcon} alt="Email" className="w-4" />
              <p className="text-xs sm:text-sm text-ellipsis overflow-hidden">
                {data?.host.email}
              </p>
            </a>
            <a
              href={`tel:${data?.host.phone}`}
              className="flex flex-nowrap gap-2 text-gray-600 items-center"
            >
              <img src={callIcon} alt="Phone" className="w-4" />
              <p className="text-xs sm:text-sm">{data?.host.phone}</p>
            </a>
          </div>
        </div>
      </div>

      {isModalOpen && <TicketModal />}
    </div>
  );
}

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;
