import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  FormControl,
  FormHelperText,
} from "@mui/material";

function StaffPermission({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) {
  const defaultPermissions = [
    {
      title: "playerRegistration",
      displayName: "Player Registration",
      type: "",
      enabled: false,
    },
    {
      title: "tournament",
      displayName: "Tournament",
      type: "",
      enabled: false,
    },
    {
      title: "staffManagement",
      displayName: "Staff Management",
      type: "",
      enabled: false,
    },
    // Add more permissions here as needed
  ];
console.log(errors)
  useEffect(() => {
    if (!values.access || values.access.length === 0) {
      setFieldValue("access", defaultPermissions);
    } else {
      const newPermissions = defaultPermissions.filter(
        (defaultPermission) =>
          !values.access.some((perm) => perm.title === defaultPermission.title)
      );
      if (newPermissions.length > 0) {
        setFieldValue("access", [...values.access, ...newPermissions]);
      }
    }
  }, [values.access, setFieldValue]);

  const handleSelectChange = (index) => (event) => {
    setFieldValue(`access[${index}].type`, event.target.value);
  };

  const handleSwitchChange = (index) => (event) => {
    setFieldValue(`access[${index}].enabled`, event.target.checked);
    if (event.target.checked && !values.access[index].type) {
      setFieldValue(`access[${index}].type`, "Read Only");
    }
  };

  const renderErrorMessages = (error) => {
    if (typeof error === "string") {
      return error;
    } else if (typeof error === "object" && error !== null) {
      return Object.values(error).join(", ");
    }
    return null;
  };

  return (
    <div className="bg-white border shadow-2xl rounded-lg p-6 sm:p-10">
      <div className="w-full sm:w-[80vw] max-w-[700px] flex flex-col gap-y-4 mx-auto">
        <div className="text-2xl font-medium">Staff Permission</div>

        <div className="flex flex-col gap-y-6">
          {values.access &&
            values.access.map((permission, index) => (
              <div
                key={index}
                className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-y-4 sm:gap-y-0 sm:gap-x-4 border-b border-slate-300 pb-4"
              >
                <div className="flex justify-between w-full sm:w-1/4 items-center">
                  {/* Title */}
                  <div className="text-sm font-medium text-slate-700">
                    {permission.displayName}
                  </div>
                  <div className="flex items-center block sm:hidden">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={permission.enabled}
                          onChange={handleSwitchChange(index)}
                          onBlur={handleBlur}
                        />
                      }
                      label=""
                    />
                    {errors.access &&
                      errors.access[index] &&
                      errors.access[index].enabled && (
                        <div className="text-red-500 text-sm">
                          {renderErrorMessages(errors.access[index].enabled)}
                        </div>
                      )}
                  </div>
                </div>

                {/* Dropdown */}
                <div className="sm:w-1/3 w-full">
                  <FormControl
                    fullWidth
                    error={Boolean(
                      errors.access &&
                        errors.access[index] &&
                        errors.access[index].type
                    )}
                  >
                    <InputLabel id={`select-label-${index}`}>
                      Access Type
                    </InputLabel>
                    <Select
                      labelId={`select-label-${index}`}
                      name={`access[${index}].type`}
                      value={permission.type}
                      onChange={handleSelectChange(index)}
                      onBlur={handleBlur}
                      label="Access Type"
                    >
                      <MenuItem value="Read Only">Read Only</MenuItem>
                      <MenuItem value="Write Only">Write Only</MenuItem>
                      <MenuItem value="Read & Write">Read & Write</MenuItem>
                    </Select>
                    {errors.access &&
                      errors.access[index] &&
                      errors.access[index].type && (
                        <FormHelperText>
                          {renderErrorMessages(errors.access[index].type)}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>

                {/* Switch */}
                <div className="sm:w-1/4 w-full flex items-center hidden sm:block">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={permission.enabled}
                        onChange={handleSwitchChange(index)}
                        onBlur={handleBlur}
                      />
                    }
                    label=""
                  />
                  {errors.access &&
                    errors.access[index] &&
                    errors.access[index].enabled && (
                      <div className="text-red-500 text-sm">
                        {renderErrorMessages(errors.access[index].enabled)}
                      </div>
                    )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

StaffPermission.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StaffPermission;
